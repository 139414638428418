.comment-card-wrapper {
  box-shadow: 0 2px 4px rgba(134, 134, 134, 0.575);
  border-radius: 10px;
  padding: 0.8rem 0.8rem 0;
  display: flex;
  flex-direction: column;
  margin: 8px;
  position: relative;
  overflow-wrap: break-word;
}

.comment-card-wrapper .trash {
  position: absolute;
  right: 15px;
  top: 10px;
  padding: 10px;
  font-size: 0.8rem;
  cursor: pointer;
}

.comment-card-text {
  font-size: 1rem;
  width: 92%;
  text-align: left;
  margin: 2px 0 5px;
  padding-left: 4px;
}

.comment-card-comments,
.edit-comment-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.edit-comment-textarea,
.comment-textarea {
  resize: none;
  border-radius: 4px;
  background: transparent;
  padding: 5px;
  margin: 5px 0 0;
  font-size: 0.9rem;
  width: 100%;
}

.edit-comment-textarea {
  height: 100%;
}

.comment-cta-buttons {
  position: absolute;
  top: 92%;
}

.comment-card-comments button {
  margin: 0 10px 0 0;
  font-size: 0.7rem;
  padding: 7px;
  margin-top: 0.6rem;
}

.comment-card-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.thumb,
.comment,
.pen-edit,
.trash,
.pen-icon,
.trash-icon,
.close-icon,
.edit-close-icon {
  padding: 10px;
  font-size: 0.9rem;
  margin-left: 5px;
  cursor: pointer;
}

.pen-edit,
.close-icon,
.edit-close-icon {
  font-size: 0.8rem;
  margin-left: 8px;
}

.pen-icon,
.trash-icon {
  font-size: 0.8rem;
  padding: 6px;
  margin: 3px;
}

.thumb:hover,
.comment:hover,
.pen-edit:hover,
.trash:hover,
.pin:hover {
  color: var(--primary-color);
}

.badge-container {
  padding: 0;
}

.badge-container button {
  margin: 2px;
}

.badge-on-icon {
  top: 0;
  right: 0;
  padding: 5px;
  background: transparent;
  color: black;
  font-size: 0.7rem;
  font-weight: 500;
}

.comment {
  padding-right: 5px;
}

.comment-text-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 2px 0;
  padding: 3px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.451);
}

.comments-title {
  font-size: 0.8rem;
  text-align: left;
  margin: 8px 0 8px;
}

.comment-text {
  width: 95%;
  text-align: left;
}

.comment-edit-icons {
  width: 4.5rem;
}

.edit-comment-cta-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.edit-comment-cta-buttons i,
.comment-edit-icons i {
  font-size: 0.75rem;
}

.edit-comment-cta-buttons .fa-times {
  font-size: 0.9rem;
}

.went-well {
  background-color: rgb(186, 255, 191);
}

.improve {
  background-color: rgb(255, 204, 204);
}

.action {
  background-color: rgb(199, 207, 255);
}

.pin{padding: 10px;
  font-size: 0.9rem;
  margin-left: 5px;
  cursor: pointer;
  display: flex;
  padding-right: 4px;
}