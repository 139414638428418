.navbar-wrapper {
  width: 100%;
  margin: auto;
  padding: 0.5rem 1rem;
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.152);
}

.navbar-logo {
  width: 10rem;
}

.publicboard-searchbar input {
  width: 25rem;
  height: 2.1rem;
  padding: 8px;
  margin-right: 1rem;
}

.navbar-auth-section > div {
  margin-left: 0.8rem;
}

@media screen and (max-width: 1024px) {
  .navbar-wrapper {
    padding: 0.5rem 1rem;
  }
}
