* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: calc(10px + 1vmin);
  text-decoration: none;
}

.App {
  text-align: center;
}
