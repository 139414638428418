.reset-password-container {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 1.2rem;
  z-index: 1;
  backdrop-filter: blur(4px) brightness(40%);
}

.reset-password-container > form {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem 1rem 1.5rem;
  border-radius: 5px;
  width: 22rem;
}

.reset-password-container .close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.no-outline-email-input {
  width: 90%;
  text-align: left;
}
