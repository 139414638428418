.board-card {
  display: flex;
  flex-direction: column;
  gap: 0.32rem;
  padding: 1rem;
  border-radius: 0.65rem;
  background-color: rgba(0, 0, 0, 0.05);
  min-width: 14rem;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%);
  position: relative;
}

.board-card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  gap: 7px;
  margin: 12px 0;
}

.board-title {
  font-weight: 700;
  font-size: 18px;
  color: rgb(28, 28, 28);
}

.board-date {
  color: gray;
  font-size: 0.9rem;
}

.board-progress {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 0.5rem;
  border-radius: 0.65rem;
  overflow: hidden;
  background-color: rgb(197, 197, 197);
  margin: 5px 0;
}

.board-progress-completed {
  width: 65%;
  height: 100%;
  border-radius: 0.3rem;
  background-color: rgb(131, 222, 255);
}

.board-card-cta {
  display: flex;
  gap: 0.6rem;
  /* position: relative; */
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.copied-clipboard {
  position: absolute;
  bottom: -2.5rem;
  font-size: 0.9rem;
  background-color: rgb(238, 238, 253);
  border: 1px solid rgb(196, 196, 255);
  padding: 8px;
  border-radius: 5px;
  z-index: 2;
}

.board-card-btn {
  background-color: transparent;
  border: 0;
  border-radius: 0.2rem;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  color: gray;
  font-weight: 500;
  cursor: pointer;
}

.board-card-btn:hover {
  background-color: #0000000c;
  color: rgb(0, 0, 0);
}

.board-card hr {
  width: 100%;
  margin: 5.5px 0;
}

.abslt-btn {
  position: absolute;
  top: 2px;
  right: 2px;
}
