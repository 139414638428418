.publicboard-wrapper {
  width: 100%;
  min-height: 100vh;
  padding: 1rem 1rem;
  flex-direction: column;
  margin: auto;
}

.flex {
  display: flex;
}

.publicboard-sorting {
  width: 8rem;
  height: 2rem;

  align-self: flex-end;
}

.publicboard-body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.publicboard-title {
  font-size: 1rem;
  padding-left: 10px;
  text-align: left;
  margin: 1rem 0 6px;
  font-weight: 500;
}

.publicboard-wentwell,
.publicboard-improve,
.publicboard-actions {
  display: flex;
  flex-direction: column;
  width: 33.3%;
}

@media screen and (max-width: 1024px) {
  .publicboard-wrapper {
    padding: 1rem 1rem;
    flex-direction: column;
    margin: auto;
  }
}

@media screen and (max-width: 768px) {
  .publicboard-wentwell,
  .publicboard-improve,
  .publicboard-actions {
    width: 50%;
  }
}

@media screen and (max-width: 600px) {
  .publicboard-wentwell,
  .publicboard-improve,
  .publicboard-actions {
    width: 100%;
  }
}
